export const Tiktok = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2806 4.08199C13.4959 3.17155 13.0634 2.00233 13.0638 0.791992H9.51658V15.2587C9.48922 16.0415 9.16401 16.783 8.60942 17.3271C8.05484 17.8711 7.31416 18.1752 6.54337 18.1753C4.91327 18.1753 3.55867 16.822 3.55867 15.142C3.55867 13.1353 5.46429 11.6303 7.4273 12.2487V8.56199C3.46684 8.02532 0 11.152 0 15.142C0 19.027 3.16837 21.792 6.53189 21.792C10.1365 21.792 13.0638 18.817 13.0638 15.142V7.80366C14.5022 8.85348 16.2291 9.41675 18 9.41366V5.80866C18 5.80866 15.8418 5.91366 14.2806 4.08199Z"
      fill="white"
    />
  </svg>
);
