import polaroid3 from "../images/polaroid3.png";
import fluffySmile from "../images/fluffySmile.png";
import polaroid4 from "../images/polaroid4.png";
import { useTranslation } from 'react-i18next';


export const About = () => {
  const { t } = useTranslation();
  
  return (
    <div id="about" className="about">
      <div className="about-title">{t('about.title')}</div>
      <div className="about-container">
        <div className="about-container-box">
          <img src={polaroid3} alt="polaroid1" />
          <div>
            <div className={"about-container-box-text"}>
              {t('about.firstCard')}
            </div>
          </div>
        </div>
        <div className="about-container-box">
          <img src={fluffySmile} alt="polaroid1" />
          <div>
            <div className={"about-container-box-text"}>
            {t('about.secondCard')}
            </div>
          </div>
        </div>
        <div className="about-container-box">
          <img src={polaroid4} alt="polaroid1" />
          <div>
            <div className={"about-container-box-text"}>
            {t('about.thirdCard')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
