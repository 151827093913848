import { useState } from "react";
import { ArrowLeft } from "../icons/ArrowLeft";
import { ArrowRight } from "../icons/ArrowRight";
import { FeelingText, SolutionText, RageRoomText } from "./BlogText";
import { useTranslation } from 'react-i18next';

export const Blog = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [active, setActive] = useState("active");

  const data = [
    { id: 0, text: <FeelingText /> },
    { id: 1, text: <RageRoomText /> },
    { id: 2, text: <SolutionText /> },
  ];

  const handleClickLeft = () => {
    setActive("");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1,
    );
    setTimeout(() => {
      setActive("active");
    }, 500);
  };

  const handleClickRight = () => {
    setActive("");
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1,
    );
    setTimeout(() => {
      setActive("active");
    }, 500);
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setActive("");
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? data.length - 1 : prevIndex - 1,
      );
      setTimeout(() => {
        setActive("active");
      }, 500);
    }
    if (isRightSwipe) {
      setActive("");
      setCurrentIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1,
      );
      setTimeout(() => {
        setActive("active");
      }, 500);
    }
  };
  return (
    <div id="blog" className="blog">
      <div className="blog-title">{t('blog.title')}</div>
      <div
        className="blog-slider"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className={`blog-slider-text ${active}`}>
          {data[currentIndex].text}
        </div>
      </div>
      <div className="service-slider-button-container">
        <div>
          <button onClick={handleClickLeft}>
            <ArrowLeft />
          </button>
        </div>
        <div>
          <button onClick={handleClickRight}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};
