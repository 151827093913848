export const Menu = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.799805 2.00002C0.799805 1.57568 0.968376 1.16871 1.26843 0.868654C1.56849 0.568596 1.97546 0.400024 2.3998 0.400024H21.5998C22.0242 0.400024 22.4311 0.568596 22.7312 0.868654C23.0312 1.16871 23.1998 1.57568 23.1998 2.00002C23.1998 2.42437 23.0312 2.83134 22.7312 3.13139C22.4311 3.43145 22.0242 3.60002 21.5998 3.60002H2.3998C1.97546 3.60002 1.56849 3.43145 1.26843 3.13139C0.968376 2.83134 0.799805 2.42437 0.799805 2.00002ZM0.799805 10C0.799805 9.57568 0.968376 9.16871 1.26843 8.86865C1.56849 8.56859 1.97546 8.40002 2.3998 8.40002H21.5998C22.0242 8.40002 22.4311 8.56859 22.7312 8.86865C23.0312 9.16871 23.1998 9.57568 23.1998 10C23.1998 10.4244 23.0312 10.8313 22.7312 11.1314C22.4311 11.4315 22.0242 11.6 21.5998 11.6H2.3998C1.97546 11.6 1.56849 11.4315 1.26843 11.1314C0.968376 10.8313 0.799805 10.4244 0.799805 10ZM0.799805 18C0.799805 17.5757 0.968376 17.1687 1.26843 16.8687C1.56849 16.5686 1.97546 16.4 2.3998 16.4H11.9998C12.4242 16.4 12.8311 16.5686 13.1312 16.8687C13.4312 17.1687 13.5998 17.5757 13.5998 18C13.5998 18.4244 13.4312 18.8313 13.1312 19.1314C12.8311 19.4315 12.4242 19.6 11.9998 19.6H2.3998C1.97546 19.6 1.56849 19.4315 1.26843 19.1314C0.968376 18.8313 0.799805 18.4244 0.799805 18Z"
      fill="#212121"
    />
  </svg>
);
