import Hero from "../images/hero.png";

export const HeroBanner = () => {
  return (
    <div className="hero-container">
      <div className="hero-wrapper">
        <img src={Hero} className="hero-logo" alt="hero-logo" />
      </div>
    </div>
  );
};
