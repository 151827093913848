import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Menu } from "../icons/Menu";
import logo from "../images/logo.png";
import useAdaptive from "../hooks/useAdaptive";



function useClickOutside(containerRef, callback) {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [containerRef]);
}

const lngs = {
  en: { nativeName: 'EN' },
  pl: { nativeName: 'PL' }
};

export const Header = ({ handleClickScroll }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const { isMobile } = useAdaptive();

  const toggleMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useClickOutside(containerRef, closeMenu);
  return (
    <header className="header">
      <img src={logo} className="header-logo" alt="logo" />
      {!isMobile && (
        <div className="header-menu">
          <div
            id="menu-about"
            className="header-menu-item"
            onClick={handleClickScroll}
          >
            {t('header.about')}
          </div>
          <div
            id="menu-offer"
            className="header-menu-item"
            onClick={handleClickScroll}
          >
            {t('header.offer')}
          </div>
          <div
            id="menu-service"
            className="header-menu-item"
            onClick={handleClickScroll}
          >
            {t('header.service')}
          </div>
          <div
            id="menu-contact"
            className="header-menu-item"
            onClick={handleClickScroll}
          >
            {t('header.contact')}
          </div>
        </div>
      )}
      <div>
        <button className="header-button">
          <a href="tel:+48 881 281 313">{t('header.callMe')}</a>
        </button>
        <div className="header-language-container">
        {Object.keys(lngs).map((lng) => (
            <div key={lng} style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }} type="submit" onClick={() => {
              i18n.changeLanguage(lng);
            }}>
              {lngs[lng].nativeName}
            </div>
          ))}
        </div>
      </div>
      {isMobile && (
        <div
          className="header-icon"
          onClick={toggleMenu}
          onTouchStart={toggleMenu}
        >
          <Menu />
        </div>
      )}
      {isOpen && isMobile && (
        <div className="header-menu-mobile" ref={containerRef}>
          <div
            id="menu-about"
            className="header-menu-item"
            onTouchStart={handleClickScroll}
          >
            {t('header.about')}
          </div>
          <div
            id="menu-offer"
            className="header-menu-item"
            onTouchStart={handleClickScroll}
          >
            {t('header.offer')}
          </div>
          <div
            id="menu-service"
            className="header-menu-item"
            onTouchStart={handleClickScroll}
          >
            {t('header.service')}
          </div>
          <div
            id="menu-contact"
            className="header-menu-item"
            onTouchStart={handleClickScroll}
          >
            {t('header.contact')}
          </div>
        </div>
      )}
    </header>
  );
};
