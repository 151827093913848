import { useMediaQuery } from "react-responsive";

function useAdaptive() {
  const isMobile = useMediaQuery({ query: "(max-width: 767.98px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1025px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  return {
    isMobile,
    isPortrait,
    isRetina,
    isDesktop,
  };
}

export default useAdaptive;
