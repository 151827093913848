export const Instagram = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4562 18.9823H3.23852C1.5502 18.9823 0.170898 17.603 0.170898 15.9147V3.69701C0.170898 2.00869 1.5502 0.629395 3.23852 0.629395H15.4562C17.1445 0.629395 18.5238 2.00869 18.5238 3.69701V15.9147C18.5238 17.6106 17.1521 18.9823 15.4562 18.9823Z"
      fill="white"
    />
    <path
      d="M9.35134 14.5203C8.09264 14.5203 6.9093 14.0304 6.01992 13.141C5.13054 12.2516 4.64062 11.0683 4.64062 9.80959C4.64062 8.55089 5.13054 7.36756 6.01992 6.47817C6.9093 5.58879 8.09264 5.09888 9.35134 5.09888C10.61 5.09888 11.7934 5.58879 12.6828 6.47817C13.5721 7.36756 14.0621 8.55089 14.0621 9.80959C14.0621 11.0683 13.5721 12.2516 12.6828 13.141C11.7858 14.0304 10.61 14.5203 9.35134 14.5203ZM9.35134 6.10132C7.30877 6.10132 5.64306 7.75949 5.64306 9.80959C5.64306 11.8522 7.30124 13.5179 9.35134 13.5179C11.3939 13.5179 13.0596 11.8597 13.0596 9.80959C13.0521 7.76703 11.3939 6.10132 9.35134 6.10132Z"
      fill="#F36E21"
    />
    <path
      d="M14.9797 4.94421C15.4792 4.94421 15.8842 4.53926 15.8842 4.03973C15.8842 3.5402 15.4792 3.13525 14.9797 3.13525C14.4801 3.13525 14.0752 3.5402 14.0752 4.03973C14.0752 4.53926 14.4801 4.94421 14.9797 4.94421Z"
      fill="#F36E21"
    />
  </svg>
);
