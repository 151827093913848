import fluffy1 from "../images/fluffy1.png";
import fluffy2 from "../images/fluffy2.png";
import smirkingFace from "../images/smirkingFace.png";
import starFace from "../images/starFace.png";
import hahaFace from "../images/hahaFace.png";
import hungryFace from "../images/hungryFace.png";
import skarb from "../images/skarb.png";
import hammer from "../images/hammer.png";
import gamepad from "../images/gamepad.png";
import soap from "../images/soap.png";
import flame from "../images/flame.png";
import { Toast } from "../components/Toast";
import { useEffect, useState } from "react";
import SlidingComponent from "./SlidingComponent";
import { useTranslation } from 'react-i18next';


export const Offer = ({ moveFluffy }) => {
  const { t } = useTranslation();
  const [val, setVal] = useState(0);

  useEffect(() => {
    setVal(moveFluffy / 10);
  }, [moveFluffy]);

  return (
    <div id="offer" className="offer">
      <div className="offer-title">{t('offer.title')}</div>
      <div className="offer-container">
        <div className="offer-container-box">
          <div>
            <div className="offer-container-box-title">
              <span>{t('offer.easyPeasyCard.title')}</span>
              <img src={smirkingFace} alt="" />
            </div>
            <div className="offer-container-box-text">
              {t('offer.easyPeasyCard.subTitle')}
            </div>
          </div>
          <div className="offer-container-box-details">
            <div className="offer-container-box-details-left">
              <div>{t('offer.easyPeasyCard.numberOfPeople')}</div>
              <div>{t('offer.easyPeasyCard.amountOfTime')}</div>
            </div>
            <div className="offer-container-box-details-right">
              {"199 zł "}
              <img src={skarb} alt="" />
            </div>
          </div>
        </div>
        <div className="offer-container-box">
          <div>
            <div className="offer-container-box-title">
              <span>{t('offer.easy.title')}</span>
              <img src={starFace} alt="" />
            </div>
            <div className="offer-container-box-text">
              {t('offer.easy.subTitle')}
            </div>
          </div>
          <div className="offer-container-box-details">
            <div className="offer-container-box-details-left">
              <div>{t('offer.easy.numberOfPeople')}</div>
              <div>{t('offer.easy.amountOfTime')}</div>
            </div>
            <div className="offer-container-box-details-right">
              {"299 zł "}
              <img src={skarb} alt="" />
            </div>
          </div>
        </div>
        <div className="offer-container-box">
          <div>
            <div className="offer-container-box-title">
              <span>{t('offer.medium.title')}</span>
              <img src={hahaFace} alt="" />
            </div>
            <div className="offer-container-box-text">
              {t('offer.medium.subTitle')}
            </div>
          </div>
          <div className="offer-container-box-details">
            <div className="offer-container-box-details-left">
              <div>{t('offer.medium.numberOfPeople')}</div>
              <div>{t('offer.medium.amountOfTime')}</div>
            </div>
            <div className="offer-container-box-details-right">
              {"499 zł "}
              <img src={skarb} alt="" />
            </div>
          </div>
        </div>
        <div className="offer-container-box">
          <div className="offer-container-box-title">
            <span>{t('offer.hard.title')}</span>
            <img src={hungryFace} alt="" />
          </div>
          <div className="offer-container-box-text">
            {t('offer.hard.subTitle')}
          </div>
          <div className="offer-container-box-details">
            <div className="offer-container-box-details-left">
              <div>{t('offer.hard.numberOfPeople')}</div>
              <div>{t('offer.hard.amountOfTime')}</div>
            </div>
            <div className="offer-container-box-details-right">
              {"999 zł "}
              <img src={skarb} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="offer-additional-services">
        <div className="offer-additional-services-title">
          {t('offer.additionalOptions.title')}
        </div>
        <div className="offer-additional-services-wrapper">
          <div className={"offer_additional_services_container"}>
            <div className="offer-additional-services-items">
              <div className="offer-additional-services-item">
                <div>
                  {t('offer.additionalOptions.glass')}
                  <span>{"50 Zł"}</span>
                </div>
                <div>
                  {t('offer.additionalOptions.printer')}
                  <span>{"50 Zł"}</span>
                </div>
              </div>
            </div>
            <div className="offer-additional-services-items">
              <div className="offer-additional-services-item">
                <div>
                  {t('offer.additionalOptions.keyboard')}
                  <span>{"20 Zł"}</span>
                </div>
                <div>
                  {t('offer.additionalOptions.mice')}
                  <span>{"10 Zł"}</span>
                </div>
              </div>
            </div>
            <div
              className={"offer-additional-services-items-tabled-divider"}
            ></div>
            <div className="offer-additional-services-items">
              <div className="offer-additional-services-item">
                <div>
                  {t('offer.additionalOptions.tv')}
                  <span>{"100 Zł"}</span>
                </div>
                <div>
                  {t('offer.additionalOptions.phone')}
                  <span>{"30 Zł"}</span>
                </div>
              </div>
            </div>
            <div className="offer-additional-services-items">
              <div className="offer-additional-services-item">
                <div>
                  {t('offer.additionalOptions.furniture')}
                  <span>{"120 Zł"}</span>
                </div>
                <div>
                  {t('offer.additionalOptions.goPro')}
                  <span>{"50 Zł"}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-additional-services-flame">
            <img src={flame} alt="flame" />
          </div>
        </div>
      </div>
      <div>
        <div className="offer-button-container">
          <a href="https://widget.zarezerwuj.pl/direct/6703f4d3-6783-4153-8661-9b7de84bcab8">
            <button className="offer-button">
            {t('offer.bookAppointment')}
            </button>
          </a>
        </div>
      </div>
      <div className="offer-fluffy-right">
        <img
          style={{ transform: `translateX(${val})` }}
          src={fluffy2}
          alt="fluffy2"
        />
      </div>
      <div className="offer-facilities">
        <SlidingComponent classes={'offer-facilities-block '} direction={'lr'}>

          <div className="offer-facilities-item">
            <img src={hammer} alt="" />
            <div>
            {t('offer.facilities.staff')}
            </div>
          </div>
          <div className="offer-facilities-item">
            <img src={gamepad} alt="" />
            <div>
            {t('offer.facilities.relax')}
            </div>
          </div>
          <div className="offer-facilities-item">
            <img src={soap} alt="" />
            <div>
            {t('offer.facilities.comfort')}
            </div>
          </div>
        </SlidingComponent>
        <Toast />
      </div>

      <div className="offer-fluffy-left">
        <img src={fluffy1} alt="fluffy1" />
      </div>
    </div>
  );
};
