import star from "../images/star.png";
import SlidingComponent from "./SlidingComponent";
import { useTranslation } from 'react-i18next';


export const Toast = () => {
  const { t } = useTranslation();

  return (
      <SlidingComponent classes={`toast scroll-reached `} direction={'rl'}>

      <div className="toast-text">
        <div>
            {t('offer.toast.firstRow')}
        </div>
        <div>
        {t('offer.toast.secondRow')}
        </div>
      </div>

        <img src={star} alt="Image Alt" />


        </SlidingComponent>

        );
};
