import logoFooter from "../images/logoFooter.png";
import heart from "../images/heart.png";
import Statute from "../docs/statute.pdf";
import Policy from "../docs/policy.pdf";
import StatuteEN from "../docs/statute_en.pdf";
import PolicyEN from "../docs/policy_en.pdf";
import { Facebook } from "../icons/Facebook";
import { Instagram } from "../icons/Instagram";
import { Tiktok } from "../icons/Tiktok";
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t, i18n } = useTranslation();


  return (
    <footer id="contact" className="footer">
      <div className="footer-left">
        <div className={"footer-left-logoWrap"}>
          <img
            src={logoFooter}
            className="footer-left-logo"
            alt="footer-logo"
          />
        </div>
        <div className={"footer-left-bottom-data"}>
          <div className="footer-left-icons-container">
            <div>
              <span>
                <a
                  id="facebook-icon"
                  href="https://www.facebook.com/people/smashandfun/100090772427682/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
              </span>
              <span>
                <a
                  id="instagram-icon"
                  href="https://instagram.com/smashandfun?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </span>
              <span>
                <a
                  id="tiktok-icon"
                  href="https://www.tiktok.com/@smashandfun"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tiktok />
                </a>
              </span>
            </div>
          </div>

          <div className={"footer_created_by"}>
            {"Created by "} <a href="https://webaste.io/">{" Webaste"}</a>
          </div>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-middle-title">{t('footer.title')}</div>
        <div className="footer-middle-contact">
          <div>
            <a href="mailto:hello@smashandfun.pl">{"hello@smashandfun.pl"}</a>
          </div>
          <div>
            <a href="tel:+48 881 281 313">{"+48 881 281 313"}</a>
          </div>
        </div>
        <div className="footer-middle-location">
          <div className={"footer-middle-location-infoBlock"}>
            <a
              href="https://maps.app.goo.gl/Ed9nJ698Gws6YsiH9"
              target="_blank"
              rel="noreferrer"
            >
              {"Postępu 19/4, 02-676 Warszawa"}
            </a>
          </div>
          <div className={"footer-middle-location-infoBlock"}>
            {t('footer.workDays')}{" 14:00 - 21:00"}
          </div>
          <div className={"footer-middle-location-infoBlock last"}>
            {t('footer.weekend')}{" 12:00 - 21:00"}
          </div>
          <div className="footer-right-policies-mobile">
            <div>
              <a href={i18n.language === 'en' ? StatuteEN : Statute}>{t('footer.termsAndConditions')}</a>
            </div>
            <div>
              <a href={i18n.language === 'en' ? PolicyEN : Policy}>{t('footer.privacyPolicy')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-right">
        <img src={heart} className="footer-right-heart" alt="footer-heart" />
        <div className="footer-right-policies">
          <div>
            <a href={i18n.language === 'en' ? StatuteEN : Statute}>{t('footer.termsAndConditions')}</a>
          </div>
          <div>
            <a href={i18n.language === 'en' ? PolicyEN : Policy}>{t('footer.privacyPolicy')}</a>
          </div>
        </div>
      </div>

      <div className={"footer_created_by_mobile"}>
        {"Created by "} <a href="https://webaste.io/">{" Webaste"}</a>
      </div>
    </footer>
  );
};
