import { useTranslation } from 'react-i18next';


export const FeelingText = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="blog-slider-text-title">
        {t('blog.feelingText.title')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.feelingText.firstRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.feelingText.firstSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.feelingText.secondRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.feelingText.secondSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.feelingText.thirdRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.feelingText.thirdSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.feelingText.fourthRow')}
      </div>
    </>
  );
};

export const RageRoomText = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="blog-slider-text-title">
        {t('blog.rageRoomText.title')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.rageRoomText.firstRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.rageRoomText.firstSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.rageRoomText.secondRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.rageRoomText.secondSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.rageRoomText.thirdRow')}
      </div>
    </>
  );
};

export const SolutionText = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="blog-slider-text-title">
        {t('blog.solutionText.title')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.solutionText.firstRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.solutionText.firstSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.solutionText.secondRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.solutionText.secondSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.solutionText.thirdRow')}
      </div>
      <div className="blog-slider-text-subtitle">
        {t('blog.solutionText.thirdSubTitle')}
      </div>
      <div className="blog-slider-text-description">
        {t('blog.solutionText.fourthRow')}
      </div>
    </>
  );
};
