import partners1 from "../images/partners1.png";
import partners2 from "../images/partners2.png";
import partners3 from "../images/partners3.png";
import fluffy2 from "../images/fluffy2.png";
import { useTranslation } from 'react-i18next';


export const Partners = () => {
  const { t } = useTranslation();
  return (
    <div id="partners" className="partners">
      <div className="partners-title">{t('partners.title')}</div>
      <div className="partners-logos">
        <div>
          <img src={partners1} alt="partners1" />
        </div>
        <div>
          {" "}
          <img src={partners2} alt="partners1" />
        </div>
        <div>
          {" "}
          <img src={partners3} alt="partners1" />
        </div>
      </div>
      <div className="offer-fluffy-right">
        <img src={fluffy2} alt="fluffy2" />
      </div>
    </div>
  );
};
