import { useState } from "react";
import { useTranslation } from 'react-i18next';


export const Switch = () => {
  const { t } = useTranslation();
  const [activeChecked, setActiveChecked] = useState(false);

  const routeChange = () => {
    setActiveChecked(true);

    setTimeout(() => {
      window.location.href =
        "https://widget.zarezerwuj.pl/direct/6703f4d3-6783-4153-8661-9b7de84bcab8";
    }, 500);
    setTimeout(() => {
      setActiveChecked(false);
    }, 900);
  };
  return (
    <div className="switch">
      <label className="switch-container">
        <input type="checkbox" checked={activeChecked} />
        <span
          className={`slider round ${activeChecked ? "active" : ""}`}
          onClick={routeChange}
        >
          <div>{t('header.bookAppointment')}</div>
        </span>
      </label>
    </div>
  );
};
