import "./App.css";
import { useEffect, useState } from "react";
import { Suspense } from 'react';
import { Header } from "./components/Header";
import { HeroBanner } from "./components/HeroBanner";
import { About } from "./components/About";
import { Offer } from "./components/Offer";
import { Service } from "./components/Service";
import { Footer } from "./components/Footer";
import { Blog } from "./components/Blog";
import { Switch } from "./components/Switch";
import { Partners } from "./components/Partners";

function App() {
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://smashandfun.com/wp-json/wp/v2/posts')
  //       const jsonData = await response.json()
  //       setData(jsonData);
  //     } catch (error) {
  //       console.log('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const [scrollReached, setScrollReached] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scrollHeight = 2500;
      if (window.innerWidth < 1024 && window.innerWidth >= 460) {
        scrollHeight = 3500;
      }
      if (window.innerWidth < 460) {
        scrollHeight = 5000;
      }
      if (window.scrollY >= scrollHeight) {
        setScrollReached(true);
      } else {
        setScrollReached(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickScroll = (e) => {
    const getElementId = () => {
      switch (e.target.id) {
        case "menu-about":
          return "about";
        case "menu-offer":
          return "offer";
        case "menu-service":
          return "service";
        case "menu-contact":
          return "contact";
        default:
          return "";
      }
    };

    const element = document.getElementById(getElementId(e.target.id));

    if (element) {
      const targetPosition = element.offsetTop; // Get the top position of the target element
      const startPosition = window.pageYOffset; // Get the current scroll position
      const distance = targetPosition - startPosition;
      window.scrollTo(0, startPosition + distance); // Update the scroll position
    }
  };

  return (
    <Suspense>
      <div className="main">
        <Header handleClickScroll={handleClickScroll} />
        <HeroBanner />
        <Switch />
        <About />
        <Offer moveFluffy={window.pageYOffset} />
        <Service />
        <Partners />
        <Blog />
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
