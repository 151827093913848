export const ArrowRight = () => (
  <svg
    width="56"
    height="46"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27.5" cy="27.5" r="27.5" fill="#F36E21" />
    <path
      d="M39.0607 29.0607C39.6464 28.4749 39.6464 27.5251 39.0607 26.9393L29.5147 17.3934C28.9289 16.8076 27.9792 16.8076 27.3934 17.3934C26.8076 17.9792 26.8076 18.9289 27.3934 19.5147L35.8787 28L27.3934 36.4853C26.8076 37.0711 26.8076 38.0208 27.3934 38.6066C27.9792 39.1924 28.9289 39.1924 29.5147 38.6066L39.0607 29.0607ZM18 29.5H38V26.5H18V29.5Z"
      fill="white"
    />
  </svg>
);
