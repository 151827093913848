export const Facebook = () => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.03115 12.7086V21.5H3.64439V12.7086H0V9.14386H3.64439V7.84689C3.64439 3.0318 5.82933 0.5 10.4523 0.5C11.8696 0.5 12.2239 0.709689 13 0.880547V4.40643C12.1311 4.26664 11.8864 4.18898 10.9838 4.18898C9.91239 4.18898 9.33874 4.46856 8.8157 5.01997C8.29267 5.57138 8.03115 6.52663 8.03115 7.89349V9.15163H13L11.6671 12.7163H8.03115V12.7086Z"
      fill="white"
    />
  </svg>
);
