import React, { useEffect, useRef, useState } from 'react';

const SlidingComponent = ({direction='lr', classes='', children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <div
            ref={ref}
            className={classes +( isVisible ? `slide-in-${direction}` : `slide-out-${direction}`)}
        >
            {children}
        </div>
    );
};

export default SlidingComponent;
