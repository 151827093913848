export const ArrowLeft = () => (
  <svg
    width="55"
    height="46"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9393 29.5607C16.3536 28.9749 16.3536 28.0251 16.9393 27.4393L26.4853 17.8934C27.0711 17.3076 28.0208 17.3076 28.6066 17.8934C29.1924 18.4792 29.1924 19.4289 28.6066 20.0147L20.1213 28.5L28.6066 36.9853C29.1924 37.5711 29.1924 38.5208 28.6066 39.1066C28.0208 39.6924 27.0711 39.6924 26.4853 39.1066L16.9393 29.5607ZM38 30H18V27H38V30Z"
      fill="white"
    />
  </svg>
);
