import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Impact.ttf";
import "./fonts/Akrobat/Akrobat-Black.otf";
import "./fonts/Akrobat/Akrobat-Bold.otf";
import "./fonts/Akrobat/Akrobat-ExtraBold.otf";
import "./fonts/Akrobat/Akrobat-Light.otf";
import "./fonts/Akrobat/Akrobat-Regular.otf";
import "./fonts/Akrobat/Akrobat-SemiBold.otf";
import "./fonts/Akrobat/Akrobat-Thin.otf";
import "./i18n"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);
const tagManagerArgs = {
  gtmId: 'GTM-TG685PW9',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
